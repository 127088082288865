import {
  connectorsForWallets
} from '@rainbow-me/rainbowkit';
import { http, createConfig } from 'wagmi';
import { localhost, base } from 'wagmi/chains';
import {
  rainbowWallet,
  metaMaskWallet,
  coinbaseWallet,
  walletConnectWallet,
  braveWallet,
  argentWallet,
  ledgerWallet,
  mewWallet,
  uniswapWallet,
} from '@rainbow-me/rainbowkit/wallets';

// const BEACHHEAD_BUILD = 'development';
const BEACHHEAD_BUILD = 'production';

const appUrl  = 'https://thebeachhead.io/';
const appIcon = 'https://thebeachhead.io/logo192.png';

const chains = (BEACHHEAD_BUILD === 'production') ?
  [base] :
  [localhost, base];

const rpcDevelopment = "http://192.168.1.80:8545/";
const rpcProduction  = "https://indulgent-withered-frog.base-mainnet.quiknode.pro/dfd2aa1f2b1f20370faa134ab06a2ad98b59f07b/";

const connectors = connectorsForWallets(
  [
    {
      groupName: 'Suggested',
      wallets: [
        coinbaseWallet,
        metaMaskWallet,
        walletConnectWallet,
        rainbowWallet,
        braveWallet,
        argentWallet,
        ledgerWallet,
        mewWallet,
        uniswapWallet,
      ],
    },
  ],
  {
    appName: 'The Beachhead',
    projectId: 'efe91c1334a52bd5a1c650ef811bd48f'
  },
);

const rainbowConfig = createConfig({
  connectors: connectors,
  chains: chains,
  ssr: false,
  appUrl,
  appIcon,
  transports: {
//    [localhost.id]:    http(rpcDevelopment),
    [base.id]:         http(rpcProduction),
  },
});

const config = {
  averageBlockTime: 2,
  readOnlyChainId: (BEACHHEAD_BUILD === 'production') ? base.id : localhost.id,
  readOnlyUrls: {
//    [localhost.id]: rpcDevelopment,
    [base.id]:      rpcProduction
  },
  multicallAddresses: {
//    [localhost.id]: "0x091e99cb1C49331a94dD62755D168E941AbD0693",
    [base.id]:      "0x091e99cb1C49331a94dD62755D168E941AbD0693"
  },
  rainbowConfig,
};

export default config;
