import "./index.css";

import { DAppProvider } from "@usedapp/core";
import React from "react";
import { createRoot } from "react-dom/client";
import config from './config';
import App from "./App";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <DAppProvider config={config}>
      <App />
    </DAppProvider>
  </React.StrictMode>
);
