import version_1_0_0 from './version_1_0_0';
import version_1_1_0 from './version_1_1_0';
import version_1_1_1 from './version_1_1_1';
import version_1_1_2 from './version_1_1_2';
import version_1_2_0 from './version_1_2_0';
import version_1_2_1 from './version_1_2_1';
import version_1_2_2 from './version_1_2_2';
import version_1_2_3 from './version_1_2_3';

const challengeConfig = {
  "versions": [
    {
      "version": "1.0.0",
      "start": 1,
      "stop": 8702342
    },
    {
      "version": "1.1.0",
      "start": 8702343,
      "stop": 17400685
    },
    {
      "version": "1.1.1",
      "start": 17400686,
      "stop": 17747172
    },
    {
      "version": "1.1.2",
      "start": 17747173,
      "stop": 18219928
    },
    {
      "version": "1.2.0",
      "start": 18219929,
      "stop": 20036708
    },
    {
      "version": "1.2.1",
      "start": 20036709,
      "stop": 20385101
    },
    {
      "version": "1.2.2",
      "start": 20385102,
      "stop": 21956002
    },
    {
      "version": "1.2.3",
      "start": 21956003,
      "stop": 9007199254740991
    },
  ],
  "weapons": {
    "default": {
      "gun": "rifle.png"
    },
    "primary": {
      "AR-23 Liberator": "primary/assault/ar-23.png",
      "AR-23A Liberator Carbine": "primary/assault/ar-23a.png",
      "AR-23C Liberator Concussive": "primary/assault/ar-23c.png",
      "AR-23P Liberator Penetrator": "primary/assault/ar-23p.png",
      "AR-61 Tenderizer": "primary/assault/ar-61.png",
      "ARC-12 Blitzer": "primary/energy/arc-12.png",
      "BR-14 Adjudicator": "primary/marksman/br-14.png",
      "CB-9 Exploding Crossbow": "primary/explosive/cb-9.png",
      "FLAM-66 Torcher": "primary/special/flam-66.png",
      "JAR-5 Dominator": "primary/explosive/jar-5.png",
      "LAS-16 Sickle": "primary/energy/las-16.png",
      "LAS-5 Scythe": "primary/energy/las-5.png",
      "MP-98 Knight": "primary/smg/mp-98.png",
      "Plas-1 Scorcher": "primary/energy/plas-1.png",
      "Plas-101 Purifier": "primary/energy/plas-101.png",
      "R-2124 Constitution": "primary/marksman/r-2124.png",
      "R-36 Eruptor": "primary/explosive/r-36.png",
      "R-63 Diligence": "primary/marksman/r-63.png",
      "R-63CS Diligence Counter Sniper": "primary/marksman/r-63cs.png",
      "SG-20 Halt": "primary/shotgun/halt.png",
      "SG-225 Breaker": "primary/shotgun/breaker.png",
      "SG-225IE Breaker Incendiary": "primary/shotgun/breaker-incendiary.png",
      "SG-225SP Breaker SPRAY&PRAY": "primary/shotgun/breaker-spray.png",
      "SG-451 Cookout": "primary/shotgun/sg-451.png",
      "SG-8 Punisher": "primary/shotgun/punisher.png",
      "SG-8P Punisher Plasma": "primary/energy/sg-8p.png",
      "SG-8S Slugger": "primary/shotgun/slugger.png",
      "SMG-32 Reprimand": "primary/smg/reprimand.png",
      "SMG-37 Defender": "primary/smg/smg-37.png",
      "SMG-72 Pummeler": "primary/smg/smg-72.png"
    },
    "secondary": {
      "GP-31 Grenade Pistol": "secondary/gp-31.png",
      "LAS-7 Dagger": "secondary/las-7.png",
      "P-2 Peacemaker": "secondary/p2-p.png",
      "P-4 Senator": "secondary/p-4s.png",
      "P-11 Stim Pistol": "secondary/p11-s.png",
      "P-19 Redeemer": "secondary/p2-r.png",
      "P-72 Crisper": "secondary/p-72.png",
      "P-113 Verdict": "secondary/p-113.png",
      "PLAS-15 Loyalist": "secondary/plas-15.png",
      "SG-22 Bushwhacker": "secondary/sg-22.png"
    },
    "grenade": {
      "G-3 Smoke": "grenade/smoke.png",
      "G-4 Gas Grenade": "grenade/gas.png",
      "G-6 Frag": "grenade/frag.png",
      "G-10 Incendiary": "grenade/incendiary.png",
      "G-12 High Explosive": "grenade/explosive.png",
      "G-13 Incendiary Impact": "grenade/g-13.png",
      "G-16 Impact": "grenade/impact.png",
      "G-23 Stun": "grenade/stun.png",
      "G-123 Thermite": "grenade/thermite.png",
      "K-2 Throwing Knife": "grenade/k2.png"
    },
  },
  "stratagems": {
    "A/AC-8 Autocannon Sentry": "Robotics Workshop/Autocannon Sentry.svg",
    "A/ARC-3 Tesla Tower": "Bridge/Tesla Tower.svg",
    "A/G-16 Gatling Sentry": "Robotics Workshop/Gatling Sentry.svg",
    "A/M-12 Mortar Sentry": "Robotics Workshop/Mortar Sentry.svg",
    "A/M-23 EMS Mortar Sentry": "Robotics Workshop/EMS Mortar Sentry.svg",
    "A/MG-43 Machine Gun Sentry": "Robotics Workshop/Machine Gun Sentry.svg",
    "A/MLS-4X Rocket Sentry": "Robotics Workshop/Rocket Sentry.svg",
    "AC-8 Autocannon": "Patriotic Administration Center/Autocannon.svg",
    "APW-1 Anti-Material Rifle": "Patriotic Administration Center/Anti-Materiel Rifle.svg",
    "ARC-3 Arc Thrower": "Engineering Bay/Arc Thrower.svg",
    "AX/AR-23 Guard Dog": "Robotics Workshop/Guard Dog.svg",
    "AX/TX-13 Guard Dog Dog Breath": "Chemical Agents/Guard Dog Breath.svg",
    "AX/LAS-5 Guard Dog Rover": "Engineering Bay/Guard Dog Rover.svg",
    "B-1 Supply Pack": "Engineering Bay/Supply Pack.svg",
    "Dark Fluid Vessel": "General Stratagems/Dark Fluid Vessel.svg",
    "E/MG-101 HMG Emplacement": "Bridge/HMG Emplacement.svg",
    "Eagle 110m Rocket Pods": "Hangar/Eagle 110MM Rocket Pods.svg",
    "Eagle 500kg Bomb": "Hangar/Eagle 500KG Bomb.svg",
    "Eagle Airstrike": "Hangar/Eagle Airstrike.svg",
    "Eagle Cluster Bomb": "Hangar/Eagle Cluster Bomb.svg",
    "Eagle Napalm Airstrike": "Hangar/Eagle Napalm Airstrike.svg",
    "Eagle Smoke Strike": "Hangar/Eagle Smoke Strike.svg",
    "Eagle Strafing Run": "Hangar/Eagle Strafing Run.svg",
    "EAT-17 Expendable Anti-Tank": "Patriotic Administration Center/Expendable Anti-Tank.svg",
    "EXO-45 Patriot Exosuit": "Robotics Workshop/Patriot Exosuit.svg",
    "EXO-49 Emancipator Exosuit": "Robotics Workshop/Emancipator Exosuit.svg",
    "FAF-14 Spear": "Patriotic Administration Center/Spear.svg",
    "FLAM-40 Flamethrower": "Patriotic Administration Center/Flamethrower.svg",
    "FX-12 Shield Generator Relay": "Bridge/Shield Generator Relay.svg",
    "GL-21 Grenade Launcher": "Engineering Bay/Grenade Launcher.svg",
    "GR-8 Recoilless Rifle": "Patriotic Administration Center/Recoilless Rifle.svg",
    "Hellbomb": "General Stratagems/Hellbomb.svg",
    "Hive Breaker Drill": "General Stratagems/Hive Breaker Drill.svg",
    "LAS-98 Laser Cannon": "Engineering Bay/Laser Cannon.svg",
    "LAS-99 Quasar Cannon": "Engineering Bay/Quasar Cannon.svg",
    "LIFT-850 Jump Pack": "Hangar/Jump Pack.svg",
    "M-105 Stalwart": "Patriotic Administration Center/Stalwart.svg",
    "MD-17 Anti-Tank Mines": "Engineering Bay/Anti-Tank Mines.svg",
    "MD-6 Anti-Personnel Minefield": "Engineering Bay/Anti-Personnel Minefield.svg",
    "MD-I4 Incendiary Mines": "Engineering Bay/Incendiary Mines.svg",
    "MG-206 Heavy Machine Gun": "Patriotic Administration Center/Heavy Machine Gun.svg",
    "MG-43 Machine Gun": "Patriotic Administration Center/Machine Gun.svg",
    "MLS-4X Commando": "Patriotic Administration Center/Commando.svg",
    "Orbital 120MM HE Barrage": "Orbital Cannons/Orbital 120MM HE Barrage.svg",
    "Orbital 380MM HE Barrage": "Orbital Cannons/Orbital 380MM HE Barrage.svg",
    "Orbital Airburst Strike": "Orbital Cannons/Orbital Airburst Strike.svg",
    "Orbital EMS Strike": "Bridge/Orbital EMS Strike.svg",
    "Orbital Gas Strike": "Bridge/Orbital Gas Strike.svg",
    "Orbital Gatling Barage": "Orbital Cannons/Orbital Gatling Barrage.svg",
    "Orbital Illumination Flare": "General Stratagems/Orbital Illumination Flare.svg",
    "Orbital Laser": "Orbital Cannons/Orbital Laser.svg",
    "Orbital Napalm Barrage": "Orbital Cannons/Orbital Napalm Barrage.svg",
    "Orbital Precision Strike": "Bridge/Orbital Precision Strike.svg",
    "Orbital Railcannon Strike": "Orbital Cannons/Orbital Railcannon Strike.svg",
    "Orbital Smoke Strike": "Bridge/Orbital Smoke Strike.svg",
    "Orbital Walking Barrage": "Orbital Cannons/Orbital Walking Barrage.svg",
    "Prospecting Drill": "General Stratagems/Prospecting Drill.svg",
    "Reinforce": "General Stratagems/Reinforce.svg",
    "Resupply": "General Stratagems/Resupply.svg",
    "RL-77 Airburst Rocket Launcher": "Patriotic Administration Center/Airburst Rocket Launcher.svg",
    "RS-422 Railgun": "Patriotic Administration Center/Railgun.svg",
    "SEAF Artillery": "General Stratagems/SEAF Artillery.svg",
    "SH-20 Balistic Shield Backpack": "Engineering Bay/Ballistic Shield Backpack.svg",
    "SH-32 Shield Generator Pack": "Engineering Bay/Shield Generator Pack.svg",
    "SOS Beacon": "General Stratagems/SOS Beacon.svg",
    "Super Earth Flag": "General Stratagems/Super Earth Flag.svg",
    "Tectonic Drill": "General Stratagems/Tectonic Drill.svg",
    "TX-41 Sterilizer": "Chemical Agents/Sterilizer.svg",
    "Upload Data": "General Stratagems/Upload Data.svg"
  },
  "1.0.0": version_1_0_0,
  "1.1.0": version_1_1_0,
  "1.1.1": version_1_1_1,
  "1.1.2": version_1_1_2,
  "1.2.0": version_1_2_0,
  "1.2.1": version_1_2_1,
  "1.2.2": version_1_2_2,
  "1.2.3": version_1_2_3,
};

export default challengeConfig;
