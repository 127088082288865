import React, { useState } from "react";
import { ethers } from "ethers";
import validator from 'validator';
import { getEthersSigner } from "../utils/ethersProvider";
import {
  ModalContainer,
  ModalContent,
  ModalInput,
  ModalButton,
  ModalButtonContainer,
  LoadingSpinner,
  ProgressBarContainer,
  ProgressBar,
  ErrorText
} from "./index";
import { abis, addresses } from '@my-app/contracts';

const CompleteChallengeModal = ({ config, isOpen, onClose, blockNumber, success, onChallengeCompleted, provider }) => {

  const [url, setUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [progressStep, setProgressStep] = useState(0);
  const [error, setError] = useState("");

  const resetState = () => {
    setUrl("");
    setIsLoading(false);
    setProgressStep(0);
    setError("");
  };

  const handleCompleteChallenge = async () => {
    if (success && !validator.isURL(url)) {
      setError("Please enter a valid URL.");
      return;
    }

    try {
      setIsLoading(true);
      setProgressStep(1);

      const signer = await getEthersSigner(config);
      const beachheadContract = new ethers.Contract(addresses.beachhead, abis.Beachhead, signer);

      const proofUrl = url || (success ? "" : "fail");

      const tx = await beachheadContract.completeChallenge(blockNumber, success, proofUrl);
      await tx.wait();

      setProgressStep(2);

      if (onChallengeCompleted) {
        onChallengeCompleted();
      }

      if (onClose) {
        onClose();
      }
    } catch (error) {
      console.error("Failed to complete challenge:", error);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (!isOpen) {
      resetState();
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <ModalContainer onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <h2>{success ? "Pass Order" : "Fail Order"}</h2>
        {isLoading ? (
          <>
            <LoadingSpinner />
            <ProgressBarContainer>
              <ProgressBar progress={progressStep === 1 ? 50 : 100} />
              <span>{progressStep === 1 ? "Submitting" : "Completed"}</span>
            </ProgressBarContainer>
          </>
        ) : (
          <>
            <ModalInput
              type="text"
              placeholder="Proof URL"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              disabled={isLoading}
            />
            {error && <ErrorText>{error}</ErrorText>}
            <ModalButtonContainer>
              <ModalButton onClick={onClose} disabled={isLoading}>Close</ModalButton>
              <ModalButton onClick={handleCompleteChallenge} disabled={isLoading}>Complete</ModalButton>
            </ModalButtonContainer>
          </>
        )}
      </ModalContent>
    </ModalContainer>
  );
};

export default CompleteChallengeModal;
