// randomItem.js

const seedrandom = require('seedrandom');

let random = Math.random;

// Function to randomly select an item from a list
export function getRandomItem(arr) {
    const randomIndex = Math.floor(random() * arr.length);
    return arr[randomIndex];
}

export function getRandomItemWeighted(arr, weights) {
    let roll = random();
    let randomIndex = Math.floor(roll * arr.length);

    while (roll > weights[randomIndex]) {
        roll = random();
        randomIndex = Math.floor(roll * arr.length);
    }
    return arr[randomIndex];
}

export function seedRandomItem(seed) {
    random = seedrandom(seed);
}

export function getRandomNumberInRange(min, max) {
    return Math.floor(random() * (max - min + 1)) + min;
}

export default getRandomItem;
