import React, { useState, useEffect, useCallback } from "react";
import { ethers } from "ethers";
import { getEthersSigner } from "../utils/ethersProvider";
import {
  ModalContainer,
  ModalContent,
  ModalInput,
  ModalButton,
  ModalButtonContainer,
  LoadingSpinner,
  ModalDetailText,
  ProgressBarContainer,
  ProgressBar,
} from "./index";
import { abis, addresses } from '@my-app/contracts';

const AcceptChallengeModal = ({ config, isOpen, onClose, blockNumber, onChallengeAccepted, provider }) => {
  const [gamerTag, setGamerTag] = useState("");
  const [url, setUrl] = useState("");
  const [amount, setAmount] = useState("");
  const [isCommitted, setIsCommitted] = useState(false);
  const [headerText, setHeaderText] = useState("Accept Order");
  const [isLoading, setIsLoading] = useState(false);
  const [challengeDetails, setChallengeDetails] = useState(null);
  const [progressStep, setProgressStep] = useState(0); // 0: Not started, 1: Approving, 2: Accepting

  const resetState = () => {
    setGamerTag("");
    setUrl("");
    setAmount("");
    setIsCommitted(false);
    setHeaderText("Accept Order");
    setIsLoading(false);
    setChallengeDetails(null);
    setProgressStep(0);
  };

  const loadPreferences = () => {
    const storedPreferences = JSON.parse(localStorage.getItem('preferences')) || {};
    const storedGamerTag = storedPreferences.gamerTag || "";
    const storedUrl = storedPreferences.streamingURL || "";
    setGamerTag(storedGamerTag);
    setUrl(storedUrl);
  };

  const checkCommitment = useCallback(async () => {
    const beachheadContract = new ethers.Contract(addresses.beachhead, abis.Beachhead, provider);
    const signer = await getEthersSigner(config);
    const userAddress = await signer.getAddress();
    const userChallenge = await beachheadContract.getUserChallenge(blockNumber, userAddress);
    if (userChallenge[2] > 0) {
      setIsCommitted(true);
      setChallengeDetails(userChallenge);
      setHeaderText("Order Accepted");
    } else {
      setIsCommitted(false);
      setHeaderText("Accept Order");
    }
  }, [config, blockNumber, provider]);

  const handleApproveAndAccept = async () => {
    try {
      setIsLoading(true);
      setProgressStep(1);
      setHeaderText("Checking Allowance...");

      const signer = await getEthersSigner(config);
      const usdcContract = new ethers.Contract(addresses.usdc, abis.erc20, signer);
      const parsedAmount = ethers.utils.parseUnits(amount, 6);

      // Check current allowance
      const currentAllowance = await usdcContract.allowance(await signer.getAddress(), addresses.beachhead);

      if (currentAllowance.lt(parsedAmount)) {
        // Approve max allowance if current allowance is insufficient
        setHeaderText("Approving...");
        const txApprove = await usdcContract.approve(addresses.beachhead, parsedAmount);
        await txApprove.wait();
      }

      setProgressStep(2);
      setHeaderText("Accepting...");

      // Proceed with accepting the challenge
      const beachheadContract = new ethers.Contract(addresses.beachhead, abis.Beachhead, signer);
      const txAccept = await beachheadContract.acceptChallenge(blockNumber, gamerTag, url, parsedAmount);
      await txAccept.wait();

      const userChallenge = await beachheadContract.getUserChallenge(blockNumber, await signer.getAddress());
      setChallengeDetails(userChallenge);
      setIsCommitted(true);
      setHeaderText("Order Accepted");

      if (onChallengeAccepted) {
        onChallengeAccepted();
      }

      // Store gamer tag and streaming URL to local storage
      const storedPreferences = JSON.parse(localStorage.getItem('preferences')) || {};
      storedPreferences.gamerTag = gamerTag;
      storedPreferences.streamingURL = url;
      localStorage.setItem('preferences', JSON.stringify(storedPreferences));

    } catch (error) {
      console.error("Failed to submit challenge:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      loadPreferences();
      checkCommitment();
    } else {
      resetState();
    }
  }, [isOpen, checkCommitment]);

  if (!isOpen) return null;

  return (
    <ModalContainer onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <h2>{headerText}</h2>
        {isCommitted ? (
          <>
            {challengeDetails && (
              <>
                <ModalDetailText>Gamer Tag: {challengeDetails[0]}</ModalDetailText>
                <ModalDetailText>Stream URL: {challengeDetails[1]}</ModalDetailText>
                <ModalDetailText>Amount: {ethers.utils.formatUnits(challengeDetails[2], 6)} USDC</ModalDetailText>
              </>
            )}
            <ModalButtonContainer>
              <ModalButton onClick={onClose}>Close</ModalButton>
            </ModalButtonContainer>
          </>
        ) : (
          <>
            {isLoading ? (
              <>
                <LoadingSpinner />
                <ProgressBarContainer>
                  <ProgressBar progress={progressStep === 1 ? 50 : 100} />
                  <span>{progressStep === 1 ? "Step 1 of 2: Approving" : "Step 2 of 2: Accepting"}</span>
                </ProgressBarContainer>
              </>
            ) : (
              <>
                <ModalInput
                  type="text"
                  placeholder="Gamer Tag"
                  value={gamerTag}
                  onChange={(e) => setGamerTag(e.target.value)}
                  disabled={isLoading}
                />
                <ModalInput
                  type="text"
                  placeholder="Stream URL (optional)"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  disabled={isLoading}
                />
                <ModalInput
                  type="number"
                  placeholder="Amount (USDC)"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  disabled={isLoading}
                />
                <ModalButtonContainer>
                  <ModalButton onClick={onClose} disabled={isLoading}>Close</ModalButton>
                  <ModalButton onClick={handleApproveAndAccept} disabled={isLoading}>Accept</ModalButton>
                </ModalButtonContainer>
              </>
            )}
          </>
        )}
      </ModalContent>
    </ModalContainer>
  );
};

export default AcceptChallengeModal;
