import React, { useState, useEffect, useCallback } from "react";
import { ethers } from "ethers";
import { getEthersSigner } from "../utils/ethersProvider";
import {
  ModalContainer,
  ModalContent,
  ModalButton,
  ModalButtonContainer,
  LoadingSpinner,
  ProgressBarContainer,
  ProgressBar,
  ModalDetailText,
} from "./index";
import { rollChallenge } from '../utils/rollChallenge';
import { abis, addresses } from '@my-app/contracts';

const WithdrawModal = ({ config, isOpen, onClose, blockNumber, onWithdrawCompleted, provider }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [headerText, setHeaderText] = useState("Withdraw Funds");
  const [challengeDetails, setChallengeDetails] = useState(null);
  const [progressStep, setProgressStep] = useState(0); // 0: Not started, 1: Settling, 2: Withdrawing
  const [isWithdrawComplete, setIsWithdrawComplete] = useState(false);
  const [totalWithdrawAmount, setTotalWithdrawAmount] = useState(null);

  const resetState = () => {
    setIsLoading(false);
    setHeaderText("Withdraw Funds");
    setChallengeDetails(null);
    setProgressStep(0);
    setIsWithdrawComplete(false);
    setTotalWithdrawAmount(null);
  };

  const fetchChallengeDetails = useCallback(async () => {
    try {
      const signer = await getEthersSigner(config);
      const userAddress = await signer.getAddress();

      const rolledChallenge = await rollChallenge(provider, blockNumber, userAddress, true);
      setChallengeDetails(rolledChallenge);

      const userShare = rolledChallenge.stats.userBalance.mul(rolledChallenge.stats.pot).div(rolledChallenge.stats.total);
      const totalAmount = rolledChallenge.stats.userBalance.add(userShare);
      setTotalWithdrawAmount(totalAmount);
    } catch (error) {
      console.error('Error fetching challenge details:', error);
    }
  }, [config, blockNumber, provider]);

  const handleWithdraw = async () => {
    try {
      setIsLoading(true);

      const signer = await getEthersSigner(config);
      const beachheadContract = new ethers.Contract(addresses.beachhead, abis.Beachhead, signer);

      const { userList, users } = challengeDetails.stats;

      if (challengeDetails.phase === 'Settlement' && userList.length !== (challengeDetails.stats.passList.length + challengeDetails.stats.failList.length)) {
        setProgressStep(1);
        setHeaderText("Settling...");

        const usersToSettle = userList.filter(user => users[user].challenge.state === 1); // 1 represents ACCEPT state
        const txSettle = await beachheadContract.settleChallenge(blockNumber, usersToSettle);
        await txSettle.wait();
      }

      setProgressStep(2);
      setHeaderText("Withdrawing...");

      const updatedChallenge = await rollChallenge(provider, blockNumber, await signer.getAddress(), true);
      if (updatedChallenge.stats.userState === 2) { // 2 represents PASS state
        const userShare = updatedChallenge.stats.userBalance.mul(updatedChallenge.stats.pot).div(updatedChallenge.stats.total);
        const totalAmount = updatedChallenge.stats.userBalance.add(userShare);

        setChallengeDetails(updatedChallenge);
        setTotalWithdrawAmount(totalAmount);

        const txFinish = await beachheadContract.finishChallenge(blockNumber);
        await txFinish.wait();
        setHeaderText("Order Completed");
      } else {
        setHeaderText("Withdrawal Completed");
      }

      setIsWithdrawComplete(true);

      if (onWithdrawCompleted) {
        onWithdrawCompleted();
      }
    } catch (error) {
      console.error("Failed to withdraw funds:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchChallengeDetails();
    } else {
      resetState();
    }
  }, [isOpen, fetchChallengeDetails]);

  if (!isOpen) return null;

  return (
    <ModalContainer onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <h2>{headerText}</h2>
        {challengeDetails && (
          <>
            <ModalDetailText>Balance: {ethers.utils.formatUnits(challengeDetails.stats.userBalance.toString(), 6)} USDC</ModalDetailText>
            <ModalDetailText>Share of Pot: {totalWithdrawAmount ? ethers.utils.formatUnits(totalWithdrawAmount.sub(challengeDetails.stats.userBalance), 6) : 'Calculating...'} USDC</ModalDetailText>
            <ModalDetailText>Total: {totalWithdrawAmount ? ethers.utils.formatUnits(totalWithdrawAmount.toString(), 6) : 'Calculating...'} USDC</ModalDetailText>
          </>
        )}
        {isLoading ? (
          <>
            <LoadingSpinner />
            <ProgressBarContainer>
              <ProgressBar progress={progressStep === 1 ? 50 : 100} />
              <span>{progressStep === 1 ? "Step 1 of 2: Settling" : "Step 2 of 2: Withdrawing"}</span>
            </ProgressBarContainer>
          </>
        ) : (
          <ModalButtonContainer>
            <ModalButton onClick={onClose}>Close</ModalButton>
            {!isWithdrawComplete && <ModalButton onClick={handleWithdraw}>Withdraw</ModalButton>}
          </ModalButtonContainer>
        )}
      </ModalContent>
    </ModalContainer>
  );
};

export default WithdrawModal;
