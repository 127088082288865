import React, { useState, useEffect, useMemo, useCallback, useRef, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ethers } from 'ethers';
import ChallengeDisplay from './ChallengeDisplay';
import { rollChallenge } from '../utils/rollChallenge';
import { getEthersSigner } from "../utils/ethersProvider";
import {
  PageContainer,
  ColumnContainer,
  MobileMissionsContainer,
  MainChallenge,
  SectionHeader,
  SectionKey,
  LoadingSpinner,
  LoadingText,
  ErrorText,
  CenteredChallengesContainer,
  CenteredChallengeLink,
  Button,
  KeyContainer,
  KeyLine,
  KeyText,
} from './index';
import { abis, addresses } from '@my-app/contracts';
import { getPreferences, canAcceptChallenge } from '../utils/preferencesUtils';

const ListChallenges = ({ config, walletConnected, provider }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const set = searchParams.get('set');

  const hasFetchedRef = useRef(false);

  const beachheadContract = useMemo(() => new ethers.Contract(addresses.beachhead, abis.Beachhead, provider), [provider]);

  const [challenges, setChallenges] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [error, setError] = useState(null);
  const [offset, setOffset] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [userAddress, setUserAddress] = useState(ethers.constants.AddressZero);
  const limit = 10;

  const saveStateToSession = () => {
    const state = {
      challenges,
      offset,
      userAddress,
      scrollPosition: window.scrollY,
    };
    sessionStorage.setItem('listChallengesState', JSON.stringify(state));
  };

  const fetchChallenges = useCallback(async (reset = false) => {
    try {
      setIsLoading(true);
      setError(null);

      let challengeIds = [];
      let userAddr = ethers.constants.AddressZero;

      if (walletConnected) {
        const signer = await getEthersSigner(config);
        if (signer) {
          userAddr = await signer.getAddress();
          setUserAddress(userAddr);
        } else {
          console.error("Failed to get signer");
        }
      }

      if (set === 'active') {
        challengeIds = await beachheadContract.getActiveChallenges();
      } else if (set === 'mine' && userAddr !== ethers.constants.AddressZero) {
        challengeIds = await beachheadContract.getUserHistory(userAddr);
      }

      // Sort the challenge IDs numerically and then reverse the array
      challengeIds = Array.from(challengeIds).sort((a, b) => a.toNumber() - b.toNumber()).reverse();

      if (!challengeIds || challengeIds.length === 0) {
        setHasMore(false);
        setIsLoading(false);
        return;
      }

      const currentOffset = reset ? 0 : offset;
      const currentChallengeIds = challengeIds.slice(currentOffset, currentOffset + limit);
      const challengesData = await Promise.all(currentChallengeIds.map(async (id) => {
        return await rollChallenge(provider, id.toNumber(), userAddr, true);
      }));

      setChallenges((prev) => {
        const newChallenges = reset ? challengesData : [...prev, ...challengesData];
        const uniqueChallenges = Array.from(new Set(newChallenges.map(ch => ch.id))).map(id => {
          return newChallenges.find(ch => ch.id === id);
        });
        return uniqueChallenges;
      });

      setOffset(currentOffset + limit);
      setHasMore(currentChallengeIds.length === limit);
    } catch (error) {
      console.error('Error fetching challenges:', error);
      setError('Failed to load challenges. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }, [config, set, provider, offset, beachheadContract, walletConnected]);

  useLayoutEffect(() => {
    const savedState = sessionStorage.getItem('listChallengesState');
    if (savedState) {
      const { challenges, offset, userAddress, scrollPosition } = JSON.parse(savedState);
      setChallenges(challenges);
      setOffset(offset);
      setUserAddress(userAddress);
      window.scrollTo(0, scrollPosition);
      hasFetchedRef.current = true;
    }
  }, []);

  useEffect(() => {
    hasFetchedRef.current = false; // Reset the ref
  }, [set]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (!hasFetchedRef.current) {
      fetchChallenges(true); // Reset the list on initial load
      hasFetchedRef.current = true;
    }
  }, [fetchChallenges]);

  const handleLoadMore = useCallback(() => {
    if (!isLoading && hasMore) {
      fetchChallenges();
    }
  }, [isLoading, hasMore, fetchChallenges]);

  useEffect(() => {
    // Reset state and fetch challenges when walletConnected or set changes
    if (set === 'mine' && !walletConnected) {
      setChallenges([]);
      setOffset(0);
      setHasMore(false);
    } else {
      setChallenges([]);
      setOffset(0);
      setHasMore(true);
      fetchChallenges(true); // Reset the list when walletConnected changes
    }
    hasFetchedRef.current = true;
  // eslint-disable-next-line
  }, [walletConnected, set]);

  useEffect(() => {
    // Reset state when the component unmounts to prevent duplicates
    return () => {
      setChallenges([]);
      setOffset(0);
      setHasMore(true);
      setIsLoading(false); // Reset to false here to ensure proper state
    };
  }, []);

  const getBorderColor = (challenge) => {
    const preferences = getPreferences();
    const canAccept = canAcceptChallenge(challenge, preferences);

    const hasNotJudged = challenge.stats.userList.some(userAddr => {
      const userStats = challenge.stats.users[userAddr];
      return (
        userStats &&
        userStats.proof &&
        userStats.proof.proofSuccess &&                                      // user reported success
        userStats.judge &&
        ethers.BigNumber.isBigNumber(userStats.judge.voteWeight) &&
        userStats.judge.voteWeight.eq(0) &&                                  // judge has not voted
        challenge.stats.userList.length !== challenge.stats.failList.length  // all users reported failing
      );
    });

    if (challenge.phase === 'Judgement') {
      if (hasNotJudged || userAddress === ethers.constants.AddressZero) {
        return 'yellow';
      }
    }

    if (set === 'active') {
      if (challenge.phase === 'Active' || challenge.phase === 'Upcoming') {
        if (!challenge.stats.userList.map(addr => addr.toLowerCase()).includes(userAddress.toLowerCase()) && canAccept) {
          return 'green';
        }
      }
    }

    if (set === 'mine') {
      if (challenge.phase === 'Judgement' && hasNotJudged) {
        return 'yellow';
      }
      if (challenge.phase === 'Settlement' &&
          ethers.BigNumber.isBigNumber(challenge.stats.userBalance) &&
          challenge.stats.userBalance.gt(0)) {
        return 'green';
      }
    }

    return 'transparent';
  };

  return (
    <PageContainer>
      <ColumnContainer>
        <SectionHeader>{set === 'active' ? 'Active Orders' : 'My Orders'}</SectionHeader>
        <SectionKey>
          <KeyContainer>
            <KeyLine color="green" />
            <KeyText>{set === 'active' ? 'Can be joined' : 'Can withdraw funds'}</KeyText>
          </KeyContainer>
          <KeyContainer>
            <KeyLine color="yellow" />
            <KeyText>Can be judged</KeyText>
          </KeyContainer>
        </SectionKey>
        {isMobile ? (
          challenges.map((challenge, index) => (
            <MobileMissionsContainer key={`${challenge.id}-${index}`}>
              <CenteredChallengeLink to={`/helldivers2/challenge/${challenge.id}`} state={{ challengeId: challenge.id }} onClick={saveStateToSession}>
                <MainChallenge style={{ border: `2px solid ${getBorderColor(challenge)}` }}>
                  <ChallengeDisplay challenge={challenge} displayAccept={false} />
                </MainChallenge>
              </CenteredChallengeLink>
            </MobileMissionsContainer>
          ))
        ) : (
          <CenteredChallengesContainer>
            {challenges.map((challenge, index) => (
              <CenteredChallengeLink key={`${challenge.id}-${index}`} to={`/helldivers2/challenge/${challenge.id}`} state={{ challengeId: challenge.id }} onClick={saveStateToSession}>
                <MainChallenge style={{ border: `2px solid ${getBorderColor(challenge)}` }}>
                  <ChallengeDisplay challenge={challenge} displayAccept={false} />
                </MainChallenge>
              </CenteredChallengeLink>
            ))}
          </CenteredChallengesContainer>
        )}
        {isLoading && <LoadingSpinner />}
        {!isLoading && hasMore && (
          <Button onClick={handleLoadMore}>Load More</Button>
        )}
        {error && <ErrorText>{error}</ErrorText>}
        {!isLoading && challenges.length === 0 && (
          <>
            <LoadingText>No challenges found.</LoadingText>
            {set === 'mine' && !walletConnected && <p>Please connect your wallet to view your judge data.</p>}
          </>
        )}
      </ColumnContainer>
    </PageContainer>
  );
};

export default ListChallenges;
