import React, { useEffect, useState, useMemo } from "react";
import { HashRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import '@rainbow-me/rainbowkit/styles.css';
import { RainbowKitProvider, ConnectButton, darkTheme } from '@rainbow-me/rainbowkit';
import { WagmiConfig, useAccount } from 'wagmi';
import { watchChainId, watchAccount } from '@wagmi/core'
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { ethers } from "ethers";
import config from './config';
import { getEthersProvider } from './utils/ethersProvider';
import {
  FixedHeader,
  Body,
  TitleContainer,
  Title,
  TitleImage,
  WalletContainer
} from "./components";
import GlobalStyles from './components/GlobalSyles';
import ChallengeSelector from './components/ChallengeSelector';
import ChallengeView from './components/ChallengeView';
import HomePage from './components/HomePage';
import HamburgerMenu from './components/HamburgerMenu';
import ListChallenges from './components/ListChallenges';
import JudgeView from './components/JudgeView';
import AboutView from './components/AboutView';
import PreferencesView from './components/PreferencesView';
import logo from './assets/Icon.png';

const ensAddress = "0xeCBaE6E54bAA669005b93342E5650d5886D54fc7";

function AppContent() {
  const [walletConnected, setWalletConnected] = useState(false);
  const location = useLocation();
  const isChallengeView = location.pathname.startsWith('/helldivers2/challenge/');
  const isJudgeView = location.pathname.startsWith('/helldivers2/judge');
  const isMyView = location.pathname.startsWith('/helldivers2/list');
  const { isConnected } = useAccount();

  // hack to get the app to reload when moving from the wrong network to the right one
  let lastChainId = config.readOnlyChainId;
  watchAccount(config.rainbowConfig, {
    onChange(data) {
      if (data.chainId === config.readOnlyChainId &&
          data.chainId !== lastChainId &&
          lastChainId !== undefined) {
        lastChainId = data.chainId;
        setWalletConnected(false);
        window.location.reload();
      } else {
        lastChainId = data.chainId;
      }
    },
  });

  watchChainId(config.rainbowConfig, {
    onChange(data) {
      setWalletConnected(false);
      window.location.reload();
    },
  });

  useEffect(() => {
    setWalletConnected(isConnected);
  }, [isConnected]);

  const provider = useMemo(() => {
    if (walletConnected) {
      const ethersProvider = getEthersProvider(config.rainbowConfig);
      if (ethersProvider) {
        return ethersProvider;
      }
    }
    return new ethers.providers.JsonRpcProvider(config.readOnlyUrls[config.readOnlyChainId], {
      name: "Base Mainnet",
      chainId: config.readOnlyChainId,
      ensAddress: ensAddress
    });
  }, [walletConnected]);

  return (
    <>
      <GlobalStyles />
      <FixedHeader>
        <TitleContainer>
          <HamburgerMenu />
          {!isChallengeView && !isJudgeView && !isMyView && (
            <Title>The Beachhead</Title>
          )}
          {(isChallengeView || isJudgeView || isMyView) && (
            <>
              <Title></Title>
              <TitleImage src={logo} alt="Logo" />
              <WalletContainer>
                <ConnectButton />
              </WalletContainer>
            </>
          )}
        </TitleContainer>
      </FixedHeader>
      <Body>
        <Routes>
          <Route path="/helldivers2/challenge/:id" element={<ChallengeView config={config.rainbowConfig} walletConnected={walletConnected} provider={provider} />} />
          <Route path="/helldivers2/browse/:id?" element={<ChallengeSelector config={config.rainbowConfig} walletConnected={walletConnected} provider={provider} />} />
          <Route path="/helldivers2/list" element={<ListChallenges config={config.rainbowConfig} walletConnected={walletConnected} provider={provider} />} />
          <Route path="/helldivers2/judge" element={<JudgeView config={config.rainbowConfig} walletConnected={walletConnected} provider={provider} />} />
          <Route path="/about" element={<AboutView provider={provider} ensAddress={ensAddress} />} />
          <Route path="/helldivers2/preferences" element={<PreferencesView provider={provider} />} />
          <Route path="/" element={<HomePage config={config.rainbowConfig} walletConnected={walletConnected} provider={provider} />} />
        </Routes>
      </Body>
    </>
  );
}

const queryClient = new QueryClient();

function App() {
  return (
    <WagmiConfig config={config.rainbowConfig}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider theme={darkTheme()}>
          <Router>
            <AppContent />
          </Router>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiConfig>
  );
}

export default App;
