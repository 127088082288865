import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
    background-color: #282c34; // Ensure this is the color you want for the entire page
    color: white; // Sets the default text color
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; // Example font-family
  }
`;

export default GlobalStyles;
