import React, { useState, useEffect } from 'react';
import challengeConfig from '../config/challengeConfig';
import { PageContainer, ColumnContainer, SectionHeader, Checkbox, Input, Label, Form } from './index';
import { getPreferences, savePreferences } from '../utils/preferencesUtils';

const getVersionForBlock = (blockNumber) => {
  const versionInfo = challengeConfig.versions.find(v => blockNumber >= v.start && blockNumber <= v.stop);
  return versionInfo ? versionInfo.version : null;
};

const PreferencesView = ({ provider }) => {
  const [preferences, setPreferences] = useState({
    gamerTag: '',
    difficulty: [],
    primary: [],
    secondary: [],
    grenade: [],
    stratagem: [],
  });
  const [versionConfig, setVersionConfig] = useState({});
  const [checkAll, setCheckAll] = useState(false);

  // Define an array of valid categories
  const validCategories = ['difficulty', 'primary', 'secondary', 'grenade', 'stratagem'];

  useEffect(() => {
    const fetchPreferences = async () => {
      try {
        const blockNumber = await provider.getBlockNumber();
        const version = getVersionForBlock(blockNumber);
        const config = challengeConfig[version] || {};

        // Retrieve preferences using getPreferences
        let storedPreferences = getPreferences();

        // Set default preferences if versionConfig changes
        if (!storedPreferences.difficulty.length && config.difficulty) {
          storedPreferences = {
            ...storedPreferences,
            difficulty: config.difficulty.map(() => true),
            primary: config.primary.map(() => true),
            secondary: config.secondary.map(() => true),
            grenade: config.grenade.map(() => true),
            stratagem: config.stratagem.map(() => true),
          };
          savePreferences(storedPreferences);
        }

        setPreferences(storedPreferences);
        setVersionConfig(config);
      } catch (error) {
        console.error('Error fetching preferences:', error);
      }
    };

    fetchPreferences();
  }, [provider]);

  const handleChange = (category, index) => {
    const updatedPreferences = { ...preferences };
    updatedPreferences[category][index] = !preferences[category][index];
    setPreferences(updatedPreferences);
    savePreferences(updatedPreferences);
  };

  const handleGamerTagChange = (event) => {
    const updatedPreferences = { ...preferences, gamerTag: event.target.value };
    setPreferences(updatedPreferences);
    savePreferences(updatedPreferences);
  };

  const handleCheckAll = () => {
    const updatedPreferences = { ...preferences };

    // Only process valid categories
    validCategories.forEach(category => {
      if (versionConfig[category]) {
        updatedPreferences[category] = versionConfig[category].map(() => !checkAll);
      }
    });

    setPreferences(updatedPreferences);
    savePreferences(updatedPreferences);
    setCheckAll(!checkAll);
  };

  return (
    <PageContainer>
      <ColumnContainer>
        <SectionHeader>Preferences</SectionHeader>
        <Form>
          <Label>Gamer Tag:</Label>
          <Input
            type="text"
            name="gamerTag"
            value={preferences.gamerTag || ''}
            onChange={handleGamerTagChange}
          />

          <Label>
            <Checkbox
              type="checkbox"
              checked={checkAll}
              onChange={handleCheckAll}
            />
            Select/Unselect All
          </Label>

          <SectionHeader>Difficulty</SectionHeader>
          {versionConfig.difficulty && versionConfig.difficulty.map((item, index) => (
            <Label key={index}>
              <Checkbox
                type="checkbox"
                checked={preferences.difficulty[index] || false}
                onChange={() => handleChange('difficulty', index)}
              />
              {item}
            </Label>
          ))}

          <SectionHeader>Primary Weapons</SectionHeader>
          {versionConfig.primary && versionConfig.primary.map((item, index) => (
            <Label key={index}>
              <Checkbox
                type="checkbox"
                checked={preferences.primary[index] || false}
                onChange={() => handleChange('primary', index)}
              />
              {item}
            </Label>
          ))}

          <SectionHeader>Secondary Weapons</SectionHeader>
          {versionConfig.secondary && versionConfig.secondary.map((item, index) => (
            <Label key={index}>
              <Checkbox
                type="checkbox"
                checked={preferences.secondary[index] || false}
                onChange={() => handleChange('secondary', index)}
              />
              {item}
            </Label>
          ))}

          <SectionHeader>Throwable</SectionHeader>
          {versionConfig.grenade && versionConfig.grenade.map((item, index) => (
            <Label key={index}>
              <Checkbox
                type="checkbox"
                checked={preferences.grenade[index] || false}
                onChange={() => handleChange('grenade', index)}
              />
              {item}
            </Label>
          ))}

          <SectionHeader>Stratagems</SectionHeader>
          {versionConfig.stratagem && versionConfig.stratagem.map((item, index) => (
            <Label key={index}>
              <Checkbox
                type="checkbox"
                checked={preferences.stratagem[index] || false}
                onChange={() => handleChange('stratagem', index)}
              />
              {item}
            </Label>
          ))}
        </Form>
      </ColumnContainer>
    </PageContainer>
  );
};

export default PreferencesView;
