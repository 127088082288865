import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import { ethers } from "ethers";
import { rollChallenge } from '../utils/rollChallenge';
import ChallengeDisplay from './ChallengeDisplay';
import { getEthersSigner } from "../utils/ethersProvider";
import { getPreferences, canAcceptChallenge } from '../utils/preferencesUtils';

import {
  PageContainer,
  MainChallenge,
  PreviousChallenge,
  NextChallenge,
  ChallengeHeader,
  NavigationButton,
  FlexLink,
  NewMissionsLink,
  ColumnContainer,
  MissionsContainer,
  MobileMissionsContainer,
  CenteredContainer,
  RowContainer,
  ReturnToCurrentLink,
  SwipeContainer,
  SectionKey,
  KeyContainer,
  KeyLine,
  KeyText
} from "./index";

const ChallengeSelector = ({ config, walletConnected, provider }) => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const initialChallengeId = id ? parseInt(id, 10) : location.state?.challengeId || null;
  const currentChallengeIdRef = useRef(initialChallengeId);

  const [isLoading, setIsLoading] = useState(true);
  const [challenge, setChallenge] = useState(null);
  const [previousChallenge, setPreviousChallenge] = useState(null);
  const [nextChallenge, setNextChallenge] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [newMissions, setNewMissions] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [swipeClass, setSwipeClass] = useState("");

  const fetchChallenge = useCallback(async (challengeId) => {
    try {
      setIsLoading(true);
      setIsFetching(true);

      // Fetch the current block number if challengeId is not provided
      const blockNumber = challengeId !== null && challengeId !== undefined
        ? challengeId
        : await provider.getBlockNumber();

      let userAddress = ethers.constants.AddressZero;

      if (walletConnected) {
        const signer = await getEthersSigner(config);
        if (signer) {
          userAddress = await signer.getAddress();
        } else {
          console.error("Failed to get signer");
        }
      }

      const rolledChallenge = await rollChallenge(provider, blockNumber, userAddress);
      setChallenge(rolledChallenge);
      currentChallengeIdRef.current = blockNumber;

      if (blockNumber > 0) {
        const previousRolledChallenge = await rollChallenge(provider, blockNumber - 1, userAddress);
        setPreviousChallenge(previousRolledChallenge);
      }

      const nextRolledChallenge = await rollChallenge(provider, blockNumber + 1, userAddress);
      setNextChallenge(nextRolledChallenge);

      // Only navigate if the challengeId is different from current
      if (challengeId && challengeId !== currentChallengeIdRef.current) {
        navigate(`/helldivers2/browse`, { state: { challengeId: blockNumber } });
      }
    } catch (error) {
      console.error("Error rolling challenge:", error);
    } finally {
      setIsLoading(false);
      setIsFetching(false);
    }
  }, [config, provider, walletConnected, navigate]);

  const updateNewMissions = useCallback(async () => {
    try {
      const mostRecentBlock = await provider.getBlockNumber();
      setNewMissions(mostRecentBlock - currentChallengeIdRef.current);
    } catch (error) {
      console.error("Error fetching the most recent block:", error);
    }
  }, [provider]);

  const handleShowNewMissions = async () => {
    if (!isFetching) {
      await fetchChallenge();
    }

    updateNewMissions();
  };

  const handleReturnToCurrent = async () => {
    if (!isFetching) {
      await fetchChallenge();
    }
  };

  useEffect(() => {
    if (!provider) {
      setIsLoading(false);
      return;
    }

    if (initialChallengeId === null && currentChallengeIdRef.current === null) {
      fetchChallenge();
    } else if (initialChallengeId !== null && challenge === null) {
      fetchChallenge(initialChallengeId);
    } else if (challenge === null) {
      fetchChallenge(currentChallengeIdRef.current);
    } else {
      updateNewMissions();
      setIsLoading(false);
      setIsFetching(false);
    }
  }, [provider, initialChallengeId, fetchChallenge, challenge, updateNewMissions]);

  useEffect(() => {
    if (!provider) {
      return;
    }

    updateNewMissions();

    const interval = setInterval(updateNewMissions, 60000);
    return () => clearInterval(interval);
  }, [provider, updateNewMissions]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handlePreviousClick = () => {
    const newChallengeId = currentChallengeIdRef.current - 1;
    setChallenge(null);
    navigate("/helldivers2/browse", { state: { challengeId: newChallengeId } });
    currentChallengeIdRef.current = newChallengeId;
    setSwipeClass("");
  };

  const handleNextClick = () => {
    const newChallengeId = currentChallengeIdRef.current + 1;
    setChallenge(null);
    navigate("/helldivers2/browse", { state: { challengeId: newChallengeId } });
    currentChallengeIdRef.current = newChallengeId;
    setSwipeClass("");
  };

  // Swipeable handlers
  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      setSwipeClass("swipe-left");
      setTimeout(() => {
        handleNextClick();
        setSwipeClass("");
      }, 300); // Duration of the swipe animation
    },
    onSwipedRight: () => {
      setSwipeClass("swipe-right");
      setTimeout(() => {
        handlePreviousClick();
        setSwipeClass("");
      }, 300); // Duration of the swipe animation
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  const preferences = getPreferences();

  if (isLoading || !challenge) {
    return (
      <PageContainer>
        <ChallengeHeader>Loading...</ChallengeHeader>
      </PageContainer>
    );
  }

  const canAccept = canAcceptChallenge(challenge, preferences);
  const canAcceptPrevious = previousChallenge ? canAcceptChallenge(previousChallenge, preferences) : false;
  const canAcceptNext = nextChallenge ? canAcceptChallenge(nextChallenge, preferences) : false;

  return (
    <PageContainer {...swipeHandlers}>
      <SwipeContainer className={swipeClass}>
        {isMobile ? (
          <>
            <ColumnContainer>
              {newMissions > 0 && (
                <MobileMissionsContainer>
                  <NewMissionsLink onClick={handleShowNewMissions}>Show {newMissions} New Missions</NewMissionsLink>
                </MobileMissionsContainer>
              )}
              {newMissions < 0 && (
                <MobileMissionsContainer>
                  <ReturnToCurrentLink onClick={handleReturnToCurrent}>
                    Return to {currentChallengeIdRef.current + newMissions}
                  </ReturnToCurrentLink>
                </MobileMissionsContainer>
              )}
              <SectionKey>
                <KeyContainer>
                  <KeyLine color="green" />
                  <KeyText>matches your preferences</KeyText>
                </KeyContainer>
              </SectionKey>
              <FlexLink to={`/helldivers2/challenge/${challenge.id}`} state={{ challengeId: currentChallengeIdRef.current }}>
                <MainChallenge style={{ border: canAccept ? '2px solid green' : 'none' }}>
                  <ChallengeDisplay challenge={challenge} displayAccept={false} />
                </MainChallenge>
              </FlexLink>
            </ColumnContainer>
            <NavigationButton style={{ left: '10px' }} onClick={handlePreviousClick}>{"<"}</NavigationButton>
            <NavigationButton style={{ right: '10px' }} onClick={handleNextClick}>{">"}</NavigationButton>
          </>
        ) : (
          <CenteredContainer>
            {newMissions > 0 && (
              <MissionsContainer>
                <NewMissionsLink onClick={handleShowNewMissions}>Show {newMissions} New Missions</NewMissionsLink>
              </MissionsContainer>
            )}
            {newMissions < 0 && (
              <MissionsContainer>
                <ReturnToCurrentLink onClick={handleReturnToCurrent}>
                  Back to {currentChallengeIdRef.current + newMissions}
                </ReturnToCurrentLink>
              </MissionsContainer>
            )}
            <SectionKey>
              <KeyContainer>
                <KeyLine color="green" />
                <KeyText>matches your preferences</KeyText>
              </KeyContainer>
            </SectionKey>
            <RowContainer>
              {previousChallenge && (
                <PreviousChallenge style={{ border: canAcceptPrevious ? '2px solid green' : 'none' }} onClick={handlePreviousClick}>
                  <ChallengeDisplay challenge={previousChallenge} displayAccept={false} />
                </PreviousChallenge>
              )}
              <FlexLink to={`/helldivers2/challenge/${challenge.id}`} state={{ challengeId: currentChallengeIdRef.current }}>
                <MainChallenge style={{ border: canAccept ? '2px solid green' : 'none' }}>
                  <ChallengeDisplay challenge={challenge} displayAccept={false} />
                </MainChallenge>
              </FlexLink>
              {nextChallenge && (
                <NextChallenge style={{ border: canAcceptNext ? '2px solid green' : 'none' }} onClick={handleNextClick}>
                  <ChallengeDisplay challenge={nextChallenge} displayAccept={false} />
                </NextChallenge>
              )}
            </RowContainer>
          </CenteredContainer>
        )}
      </SwipeContainer>
    </PageContainer>
  );
}

export default ChallengeSelector;
