import erc20ABI          from "./abis/erc20.json";
import ownableABI        from "./abis/ownable.json";
import Multicall3ABI     from "./abis/Multicall3.json";
import BeachheadTokenABI from "./abis/BeachheadToken.json";
import BeachheadJudgeABI from "./abis/BeachheadJudge.json";
import BeachheadABI      from "./abis/Beachhead.json";

const abis = {
  erc20:          erc20ABI,
  ownable:        ownableABI,
  Multicall3:     Multicall3ABI,
  BeachheadToken: BeachheadTokenABI,
  BeachheadJudge: BeachheadJudgeABI,
  Beachhead:      BeachheadABI,
};

export default abis;
