import React, { useState } from 'react';
import { ethers } from 'ethers';
import {
  UserContainer,
  UserTitle,
  UserVideoContainer,
  UserDetails,
  ProgressBar,
  ProgressBarRed,
  ProgressBarContainer,
  UserHeader,
  StatusText,
  JudgeModalButton,
  StyledLink
} from './index';
import JudgeChallengeModal from './JudgeChallengeModal'; // Import the new modal

const UserDisplay = ({ config, walletConnected, userAddress, challenge, refreshChallenge }) => {
  const challengeId = challenge.id;
  const user = challenge.stats.users[userAddress] || {};
  const votesPass = ethers.BigNumber.from(user.votes.pass || 0);
  const votesFail = ethers.BigNumber.from(user.votes.fail || 0);
  const votesTotal = votesPass.add(votesFail);
  const votePassPercent = votesTotal.gt(0) ? votesPass.mul(100).div(votesTotal).toNumber() : 0;
  const voteFailPercent = votesTotal.gt(0) ? votesFail.mul(100).div(votesTotal).toNumber() : 0;

  const [isJudgeModalOpen, setIsJudgeModalOpen] = useState(false);

  let userURL;
  if (user.proof.userReported) {
    userURL = user.proof.proofURL;
  } else {
    userURL = user.challenge.url;
  }

  const renderVideo = (url) => {
    if (!url) return null;

    let embedUrl;
    if (url.includes('youtube.com') || url.includes('youtu.be')) {
      const videoId = url.split('v=')[1]?.split('&')[0] || url.split('/').pop();
      embedUrl = `https://www.youtube.com/embed/${videoId}`;
      return <iframe src={embedUrl} allowFullScreen title="User Video"></iframe>;
    } else if (url.includes('twitch.tv')) {
      const channelId = url.split('/').pop();
      embedUrl = `https://player.twitch.tv/?channel=${channelId}&parent=localhost`;
      return <iframe src={embedUrl} allowFullScreen title="User Video"></iframe>;
    } else if (url.includes('http')) {
      return <StyledLink to={url} target="_blank">View Proof</StyledLink>;
    } else {
      return null;
    }
  };

  // status logic
  let status = '';
  let statusColor = '';
  let userJudged = false;
  const userAddressLower = userAddress.toLowerCase();
  const userList = challenge.stats.userList.map(addr => addr.toLowerCase());
  const passList = challenge.stats.passList.map(addr => addr.toLowerCase());
  const failList = challenge.stats.failList.map(addr => addr.toLowerCase());

  if (!user.proof.userReported && challenge.phase === 'Judgement') {
    status = 'Failed to Report';
    statusColor = 'red';
  } else if (user.proof.proofURL && !passList.includes(userAddressLower) && !failList.includes(userAddressLower)) {
    status = 'Status: Awaiting Judgement';
    statusColor = 'yellow';
  } else if (userList.includes(userAddressLower) && !passList.includes(userAddressLower) && !failList.includes(userAddressLower)) {
    status = 'Status: Accepted';
    statusColor = 'yellow';
  } else if (failList.includes(userAddressLower)) {
    status = 'Status: Failed';
    statusColor = 'red';
    userJudged = true;
  } else if (passList.includes(userAddressLower)) {
    status = 'Status: Passed';
    statusColor = 'green';
    userJudged = true;
  }

  // Determine if the judge has already voted
  const judgeVoted = user.judge.voteWeight && ethers.BigNumber.from(user.judge.voteWeight).gt(0);

  // URL conditions
  const displayURL = (renderVideo(userURL) !== null);

  // judging conditions
  const displayJudging = walletConnected &&
    challenge.stats.participants > 0 &&
    !userJudged &&
    user.proof.userReported &&
    challenge.phase === 'Judgement';

  return (
    <UserContainer>
      <UserTitle>
        <span>{user.challenge?.tag || 'Unknown'}</span>
        <span>{`$${ethers.utils.formatUnits(user.challenge?.balance || 0, 6).toString()}`}</span>
      </UserTitle>
      {displayURL && (
        <>
          <UserVideoContainer>
            {renderVideo(userURL)}
          </UserVideoContainer>
        </>
      )}
      <StatusText color={statusColor}>{status}</StatusText>
      {displayJudging && (
        <>
          <UserDetails>
            <UserHeader>Votes</UserHeader>
            <ProgressBarContainer>
              <ProgressBar progress={votePassPercent}>
                <span>Passing: {`${parseFloat(ethers.utils.formatUnits(votesPass, 18)).toFixed(2)}`}</span>
              </ProgressBar>
            </ProgressBarContainer>
            <ProgressBarContainer>
              <ProgressBarRed progress={voteFailPercent}>
                <span>Failing: {`${parseFloat(ethers.utils.formatUnits(votesFail, 18)).toFixed(2)}`}</span>
              </ProgressBarRed>
            </ProgressBarContainer>
            <JudgeModalButton onClick={() => setIsJudgeModalOpen(true)}>
              {judgeVoted ? 'Change Vote' : 'Judge'}
            </JudgeModalButton>
          </UserDetails>
          <JudgeChallengeModal
            config={config}
            isOpen={isJudgeModalOpen}
            onClose={() => setIsJudgeModalOpen(false)}
            challengeId={challengeId}
            userAddress={userAddress}
            voteWeight={challenge.stats.judgeBalance}
            refreshChallenge={refreshChallenge}
          />
        </>
      )}
    </UserContainer>
  );
};

export default UserDisplay;
