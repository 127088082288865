import React, { useState } from "react";
import { ethers } from "ethers";
import { useNavigate } from 'react-router-dom';
import { getEthersSigner } from "../utils/ethersProvider";
import {
  ModalContainer,
  ModalContent,
  ModalButtonContainer,
  LoadingSpinner,
  ProgressBarContainer,
  ProgressBar,
  PassButton,
  FailButton,
  JudgeVoteWeight,
  ModalButton,
} from "./index";
import { abis, addresses } from '@my-app/contracts';

const JudgeChallengeModal = ({ config, isOpen, onClose, challengeId, userAddress, voteWeight, refreshChallenge }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [progressStep, setProgressStep] = useState(0); // 0: Not started, 1: Voting
  const [headerText, setHeaderText] = useState("Judge Participant");
  const navigate = useNavigate();

  const handleVote = async (success) => {
    try {
      setIsLoading(true);
      setProgressStep(1);
      setHeaderText("Casting Vote...");

      const signer = await getEthersSigner(config);
      const judgeContract = new ethers.Contract(addresses.beachheadJudge, abis.BeachheadJudge, signer);

      const txVote = await judgeContract.judgeChallenge(challengeId, [userAddress], [success]);
      await txVote.wait();

      setHeaderText("Vote Cast");
      refreshChallenge();
      onClose();
    } catch (error) {
      console.error("Failed to cast vote:", error);
    } finally {
      setIsLoading(false);
      setProgressStep(0);
    }
  };

  const handleGoToJudgeView = () => {
    navigate('/helldivers2/judge');
    onClose();
  };

  return (
    isOpen ? (
      <ModalContainer onClick={onClose}>
        <ModalContent onClick={(e) => e.stopPropagation()}>
          <h2>{headerText}</h2>
          <JudgeVoteWeight>{`Vote Weight: ${ethers.utils.formatUnits(voteWeight, 18)}`}</JudgeVoteWeight>
          {isLoading ? (
            <>
              <LoadingSpinner />
              <ProgressBarContainer>
                <ProgressBar progress={progressStep === 1 ? 100 : 0} />
                <span>Voting...</span>
              </ProgressBarContainer>
            </>
          ) : (
            <>
              {ethers.BigNumber.from(voteWeight).isZero() ? (
                <ModalButtonContainer>
                  <ModalButton onClick={handleGoToJudgeView}>Must Add Vote Weight</ModalButton>
                </ModalButtonContainer>
              ) : (
                <ModalButtonContainer>
                  <FailButton onClick={() => handleVote(false)}>Fail</FailButton>
                  <PassButton onClick={() => handleVote(true)}>Pass</PassButton>
                </ModalButtonContainer>
              )}
            </>
          )}
        </ModalContent>
      </ModalContainer>
    ) : null
  );
};

export default JudgeChallengeModal;
