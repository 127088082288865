import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { ethers } from 'ethers';
import ChallengeDisplay from './ChallengeDisplay';
import AcceptChallengeModal from './AcceptChallengeModal';
import CompleteChallengeModal from './CompleteChallengeModal';
import WithdrawModal from './WithdrawModal';
import UserDisplay from './UserDisplay';
import { rollChallenge } from '../utils/rollChallenge';
import { getEthersSigner } from "../utils/ethersProvider";
import {
  MobileMissionsContainer,
  PageContainer,
  MainChallenge,
  ButtonContainer,
  FailedButton,
  PassedButton,
  WithdrawButton,
  MessageTextRed,
  MessageTextYellow,
  MessageTextGreen,
  ColumnContainer,
  MissionsContainer,
  ParticipantHeader
} from "./index";

const ChallengeView = ({ config, walletConnected, provider }) => {
  const { id } = useParams();
  const [challenge, setChallenge] = useState(null);
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [isCompleteModalOpen, setIsCompleteModalOpen] = useState(false);
  const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false);
  const [isUserInChallenge, setIsUserInChallenge] = useState(false);
  const [hasCompletedChallenge, setHasCompletedChallenge] = useState(false);
  const [hasFailedChallenge, setHasFailedChallenge] = useState(false);
  const [hasPassedChallenge, setHasPassedChallenge] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const fetchChallenge = useCallback(async () => {
    const blockNumber = parseInt(id, 10);

    try {
      let userAddress = ethers.constants.AddressZero;

      if (walletConnected) {
        const signer = await getEthersSigner(config);
        if (signer) {
          userAddress = await signer.getAddress();
        } else {
          console.error("Failed to get signer");
        }
      }

      const rolledChallenge = await rollChallenge(provider, blockNumber, userAddress, true);
      setChallenge(rolledChallenge);

      if (walletConnected) {
        const userList = rolledChallenge.stats.userList?.filter(address => typeof address === 'string').map(address => address.toLowerCase()) || [];
        const passList = rolledChallenge.stats.passList?.filter(address => typeof address === 'string').map(address => address.toLowerCase()) || [];
        const failList = rolledChallenge.stats.failList?.filter(address => typeof address === 'string').map(address => address.toLowerCase()) || [];
        const userReported = rolledChallenge.stats.userReported;

        const isUserInChallenge = userList.includes(userAddress.toLowerCase());
        const hasPassedChallenge = passList.includes(userAddress.toLowerCase());
        const hasFailedChallenge = failList.includes(userAddress.toLowerCase());
        const hasCompletedChallenge = userReported;

        setIsUserInChallenge(isUserInChallenge);
        setHasPassedChallenge(hasPassedChallenge);
        setHasFailedChallenge(hasFailedChallenge);
        setHasCompletedChallenge(hasCompletedChallenge);
      }
    } catch (error) {
      console.error('Error rolling challenge:', error);
    }
  }, [config, id, provider, walletConnected]);

  useEffect(() => {
    fetchChallenge();
  }, [fetchChallenge]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleAcceptChallenge = () => {
    setIsAcceptModalOpen(true);
  };

  const handleCompleteChallenge = (isSuccess) => {
    setSuccess(isSuccess);
    setIsCompleteModalOpen(true);
  };

  const handleWithdrawFunds = () => {
    setIsWithdrawModalOpen(true);
  };

  if (!challenge) {
    return <div>Loading...</div>;
  }

  // accept conditions
  const displayAccept = walletConnected &&
    !isUserInChallenge &&
    (challenge.phase === 'Upcoming' || challenge.phase === 'Active');

  // complete conditions
  const displayComplete = walletConnected &&
    isUserInChallenge &&
    !hasCompletedChallenge &&
    challenge.phase === 'Active';

  // waiting conditions
  const displayWaiting = walletConnected &&
    hasCompletedChallenge &&
    !hasFailedChallenge &&
    (challenge.phase === 'Active');

  // judging conditions
  const displayJudging = walletConnected &&
    challenge.stats.participants > 0 &&
    challenge.phase === 'Judgement';

  // withdraw conditions
  const displayWithdraw = walletConnected &&
    challenge.stats.userBalance.toNumber() > 0 &&
    ((hasCompletedChallenge && !hasFailedChallenge) || hasPassedChallenge) &&
    challenge.phase === 'Settlement';

  // finished conditions
  const displayFinished = walletConnected &&
    challenge.stats.userBalance.toNumber() === 0 &&
    ((hasCompletedChallenge && !hasFailedChallenge) || hasPassedChallenge) &&
    challenge.phase === 'Settlement';

  // failed conditions
  const displayFailed = walletConnected &&
    hasCompletedChallenge &&
    hasFailedChallenge &&
    challenge.phase !== 'Judgement';

  const userList = challenge.stats.userList || [];
  const userDisplays = userList.map(userAddr => {
    return <UserDisplay config={config} key={userAddr} walletConnected={walletConnected} userAddress={userAddr} challenge={challenge} refreshChallenge={fetchChallenge} />;
  });

  return (
    <PageContainer>
      {isMobile ? (
        <MobileMissionsContainer>
          <ColumnContainer>
            <MainChallenge>
              <ChallengeDisplay
                challenge={challenge}
                displayAccept={displayAccept}
                onAcceptChallenge={handleAcceptChallenge}
              />
              {displayComplete && (
                <ButtonContainer>
                  <FailedButton onClick={() => handleCompleteChallenge(false)}>Failed</FailedButton>
                  <PassedButton onClick={() => handleCompleteChallenge(true)}>Passed</PassedButton>
                </ButtonContainer>
              )}
              {displayWithdraw && (
                <ButtonContainer>
                  <WithdrawButton onClick={handleWithdrawFunds}>Withdraw Funds</WithdrawButton>
                </ButtonContainer>
              )}
              {displayJudging && (
                <MessageTextYellow>Judge Order Below</MessageTextYellow>
              )}
              {displayWaiting && (
                <MessageTextYellow>Waiting for Next Phase</MessageTextYellow>
              )}
              {displayFinished && (
                <MessageTextGreen>Thanks for Playing</MessageTextGreen>
              )}
              {displayFailed && (
                <MessageTextRed>Better Luck Next Time</MessageTextRed>
              )}
            </MainChallenge>
            {userList.length > 0 && (
              <ParticipantHeader>
                Participants
              </ParticipantHeader>
            )}
            {userDisplays}
          </ColumnContainer>
        </MobileMissionsContainer>
      ) : (
        <MissionsContainer>
          <ColumnContainer>
            <MainChallenge>
              <ChallengeDisplay
                challenge={challenge}
                displayAccept={displayAccept}
                onAcceptChallenge={handleAcceptChallenge}
              />
              {displayComplete && (
                <ButtonContainer>
                  <FailedButton onClick={() => handleCompleteChallenge(false)}>Failed</FailedButton>
                  <PassedButton onClick={() => handleCompleteChallenge(true)}>Passed</PassedButton>
                </ButtonContainer>
              )}
              {displayWithdraw && (
                <ButtonContainer>
                  <WithdrawButton onClick={handleWithdrawFunds}>Withdraw Funds</WithdrawButton>
                </ButtonContainer>
              )}
              {displayJudging && (
                <MessageTextYellow>Judge Orders Below</MessageTextYellow>
              )}
              {displayWaiting && (
                <MessageTextYellow>Waiting for Next Phase</MessageTextYellow>
              )}
              {displayFinished && (
                <MessageTextGreen>Thanks for Playing</MessageTextGreen>
              )}
              {displayFailed && (
                <MessageTextRed>Better Luck Next Time</MessageTextRed>
              )}
            </MainChallenge>
            {userList.length > 0 && (
              <ParticipantHeader>
                Participants
              </ParticipantHeader>
            )}
            {userDisplays}
          </ColumnContainer>
        </MissionsContainer>
      )}
      <AcceptChallengeModal
        config={config}
        isOpen={isAcceptModalOpen}
        onClose={() => setIsAcceptModalOpen(false)}
        blockNumber={challenge.id}
        onChallengeAccepted={fetchChallenge}
        provider={provider}
      />
      <CompleteChallengeModal
        config={config}
        isOpen={isCompleteModalOpen}
        onClose={() => {
          setIsCompleteModalOpen(false);
          fetchChallenge(); // Ensure state is refreshed after completing the challenge
        }}
        blockNumber={challenge.id}
        success={success}
        provider={provider}
      />
      <WithdrawModal
        config={config}
        isOpen={isWithdrawModalOpen}
        onClose={() => setIsWithdrawModalOpen(false)}
        blockNumber={challenge.id}
        onWithdrawCompleted={fetchChallenge}
        provider={provider}
      />
    </PageContainer>
  );
};

export default ChallengeView;
