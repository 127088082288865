import React, { useState, useEffect, useCallback } from "react";
import { ethers } from "ethers";
import { abis, addresses } from "@my-app/contracts";
import JudgeActionModal from "./JudgeActionModal";
import { getEthersSigner } from "../utils/ethersProvider";
import {
  PageContainer,
  ColumnContainer,
  SectionHeader,
  Button,
  ErrorText,
  LoadingSpinner,
  ButtonContainer,
  VoteWeightText,
  CenteredTextContainer,
  JudgeContainer,
  TextBlock,
  StyledLink,
  YellowText
} from "./index";

const JudgeView = ({ config, walletConnected, provider }) => {
  const [bchdBalance, setBchdBalance] = useState(0);
  const [voteWeight, setVoteWeight] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isJoinModalOpen, setIsJoinModalOpen] = useState(false);
  const [isExitModalOpen, setIsExitModalOpen] = useState(false);

  const fetchJudgeData = useCallback(async () => {
    try {
      setIsLoading(true);
      setError(null);

      let userAddress = ethers.constants.AddressZero;

      if (walletConnected) {
        const signer = await getEthersSigner(config);
        if (signer) {
          userAddress = await signer.getAddress();
        } else {
          console.error("Failed to get signer");
        }
      }

      const multicall = new ethers.Contract(addresses.multicall3, abis.Multicall3, provider);
      const judgeInterface = new ethers.utils.Interface(abis.BeachheadJudge);
      const tokenInterface = new ethers.utils.Interface(abis.BeachheadToken);

      const calls = [
        {
          name: "balances",
          target: addresses.beachheadJudge,
          callData: judgeInterface.encodeFunctionData("balances", [userAddress]),
        },
        {
          name: "balanceOf",
          target: addresses.beachheadToken,
          callData: tokenInterface.encodeFunctionData("balanceOf", [userAddress]),
        },
      ];

      const results = await multicall.callStatic.aggregate3(calls);
      const decodedResults = results.map(({ success, returnData }, i) => {
        if (!success) throw new Error(`Call failed for ${calls[i].name}`);
        return calls[i].target === addresses.beachheadJudge
          ? judgeInterface.decodeFunctionResult(calls[i].name, returnData)
          : tokenInterface.decodeFunctionResult(calls[i].name, returnData);
      });

      setVoteWeight(ethers.utils.formatUnits(decodedResults[0][0], 18));
      setBchdBalance(ethers.utils.formatUnits(decodedResults[1][0], 18));
    } catch (error) {
      console.error("Error fetching judge data:", error);
      setError("Failed to load judge data. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  }, [config, provider, walletConnected]);

  useEffect(() => {
    fetchJudgeData();
  }, [fetchJudgeData]);

  const handleJoinClick = () => setIsJoinModalOpen(true);
  const handleExitClick = () => setIsExitModalOpen(true);

  const uniswapBchdUrl = `https://app.uniswap.org/swap?theme=dark&inputCurrency=ETH&outputCurrency=${addresses.beachheadToken}&chain=base`;

  return (
    <PageContainer>
    <JudgeContainer>
      <ColumnContainer>
        <SectionHeader>Judge Dashboard</SectionHeader>
        {!walletConnected && <YellowText>Please connect your wallet to view your judge dashboard.</YellowText>}
        {error && <ErrorText>{error}</ErrorText>}
        {isLoading && walletConnected ? (
          <LoadingSpinner />
        ) : (
          walletConnected && (
            <>
              <CenteredTextContainer>
                <p>BCHD Balance</p>
                <p>{bchdBalance}</p>
                <p>Vote Weight</p>
                <VoteWeightText color={voteWeight > 0 ? ' #4bbf73' : ' #a94444'}>{voteWeight}</VoteWeightText>
              </CenteredTextContainer>
              <ButtonContainer>
                <Button onClick={handleExitClick}>Decrease</Button>
                <Button onClick={handleJoinClick}>Increase</Button>
              </ButtonContainer>
            </>
          )
        )}
        <SectionHeader>Judging</SectionHeader>
        <TextBlock>
          Judges are anyone with a Beachhead token (BCHD). This token can be easily purchased from the <StyledLink to={uniswapBchdUrl} target="_blank">market</StyledLink>.
          Judges must add this token to the BeachheadJudge contract using this page in order to gain vote weight.
          Once BeachheadJudge is funded, a judge may use that vote weight to vote on the results of any player participating in a challenge.
          All their vote weight will be put on either pass or fail. During the challenge's judgement phase a judge may be convinced by the player, other judges, or the data to change their vote, this open discussion and any resulting voting changes are allowed.
          If you're a judge, or a player wishing to discuss your challenge, you can have those discussions in #judging on <StyledLink to="https://discord.gg/4f3hcJZv" target="_blank">The Beachhead Discord server</StyledLink>.
        </TextBlock>
        <SectionHeader>Rules</SectionHeader>
        <TextBlock>
          Here are some FAQs and guidlines for judging on The Beachhead:
          <br /><br />
          1. Always be honest and fair when judging players.  The Beachhead is a community of gamers and we want to foster a positive and fair environment that causes players to come back.  The long-term incentives of the Beachhead Token (BCHD) should encourage judges to grow The Beachhead, and judge players honestly.  Read more about the tokenomics on the <StyledLink to="/about">About</StyledLink> page.
          <br /><br />
          2. Ensure the gamer tag on the challenge matches the gamer tag of the player you are judging. If the gamer tag does not match, the challenge participant may have been impersonated.
          <br /><br />
          3. Ensure the player is fighting the appropriate enemy type.  If the challenge specifies a certain enemy type, the player must be fighting that enemy type to pass the challenge.
          <br /><br />
          4. Ensure the mission difficulty is greater than or equal to the difficulty of the challenge.  If the mission is too easy, the player may not have been challenged enough to warrant a pass.
          <br /><br />
          5. Ensure the player selects a mission with the appropriate duration.  If the challenge specifies a certain mission duration, the player must select a mission with that duration to pass the challenge.
          <br /><br />
          6. Ensure the teamplay of the challenge, Squad or Solo, is adhered to.  If the challenge specifies a certain teamplay style, the player must adhere to that style to pass the challenge.
          <br /><br />
          7. At the end of any mission, the player MUST extract.  Ensure they extract, and that the number of starts they get is greater than or equal to the number of stars required by the challenge.
          <br /><br />
          8. Ensure that the player's loadout matches the challenge requirements.  Validate they are using the appropriate primary weapon, secondary weapon, throwing weapon, and stratagems (in any order) to pass the challenge.
          <br /><br />
          9. Player loadouts must be chosen when dropping in to a mission, but the player may change weapons once in the mission.  This not only includes using found items, but any items brought by that player's squad or provided as modifiers.
          <br /><br />
        </TextBlock>
        <JudgeActionModal
          config={config}
          isOpen={isJoinModalOpen}
          onClose={() => setIsJoinModalOpen(false)}
          action="join"
          maxAmount={bchdBalance}
          refreshJudgeData={fetchJudgeData}
        />
        <JudgeActionModal
          config={config}
          isOpen={isExitModalOpen}
          onClose={() => setIsExitModalOpen(false)}
          action="exit"
          maxAmount={voteWeight}
          refreshJudgeData={fetchJudgeData}
        />
      </ColumnContainer>
    </JudgeContainer>
    </PageContainer>
  );
};

export default JudgeView;
