import { getClient, getConnectorClient } from '@wagmi/core';
import { providers } from 'ethers';

/** Convert client to provider */
export function clientToProvider(client) {
  const { chain, transport } = client;
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  };
  if (transport.type === 'fallback') {
    return new providers.FallbackProvider(
      transport.transports.map(({ value }) => new providers.JsonRpcProvider(value?.url, network)),
    );
  }
  return new providers.JsonRpcProvider(transport.url, network);
}

/** Convert viem Public Client to ethers.js Provider */
export function getEthersProvider(config, { chainId } = {}) {
  const client = getClient(config, { chainId });
  if (!client) return null;
  return clientToProvider(client);
}

/** Convert client to signer */
export function clientToSigner(client) {
  const { account, chain, transport } = client;
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  };
  const provider = new providers.Web3Provider(transport, network);
  const signer = provider.getSigner(account.address);
  return signer;
}

/** Convert viem Public Client to ethers.js Signer */
export async function getEthersSigner(config, { chainId } = {}) {
  const maxRetries = 5;
  let retries = 0;

  // gross hack to beat race condition until I figure out where it is
  while (retries < maxRetries) {
    try {
      const client = await getConnectorClient(config, { chainId });
      if (!client) throw new Error('Client is not defined');
      return clientToSigner(client);
    } catch (error) {
      // console.error("Failed to get Ethers signer, retrying:", error);
      retries += 1;
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  }

  console.error("Failed to get Ethers signer after retries");
  return null;
}
