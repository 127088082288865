import challengeConfig from '../config/challengeConfig';

export const getPreferences = () => {
  // Get the preferences from localStorage
  let preferences = JSON.parse(localStorage.getItem('preferences')) || {
    gamerTag: '',
    difficulty: [],
    primary: [],
    secondary: [],
    grenade: [],
    stratagem: [],
  };

  // Check if the old 'stratagems' field exists
  if (preferences.stratagems) {
    // Copy 'stratagems' to 'stratagem' and remove 'stratagems'
    preferences.stratagem = preferences.stratagems;
    delete preferences.stratagems;

    // Save the updated preferences back to localStorage
    savePreferences(preferences);
  }

  return preferences;
};

export const savePreferences = (preferences) => {
  localStorage.setItem('preferences', JSON.stringify(preferences));
};

export const canAcceptChallenge = (challenge, preferences) => {
  const config = challengeConfig[challenge.version];

  if (!config || !challenge.mission || !challenge.loadout) return false;

  const isValid = (category, selected, challengeCategory) => {
    // Ensure `selected` is an array and not undefined
    if (!Array.isArray(selected)) return false;
    return selected.some((item, index) => item && config[category][index] === challengeCategory);
  };

  const validDifficulty = challenge.mission.difficulty
    ? isValid('difficulty', preferences.difficulty || [], challenge.mission.difficulty)
    : false;

  const validPrimary = challenge.loadout.primary
    ? isValid('primary', preferences.primary || [], challenge.loadout.primary)
    : false;

  const validSecondary = challenge.loadout.secondary
    ? isValid('secondary', preferences.secondary || [], challenge.loadout.secondary)
    : false;

  const validGrenade = challenge.loadout.grenade
    ? isValid('grenade', preferences.grenade || [], challenge.loadout.grenade)
    : false;

  const validStratagem1 = challenge.loadout.stratagem1
    ? isValid('stratagem', preferences.stratagem || [], challenge.loadout.stratagem1)
    : false;

  const validStratagem2 = challenge.loadout.stratagem2
    ? isValid('stratagem', preferences.stratagem || [], challenge.loadout.stratagem2)
    : false;

  const validStratagem3 = challenge.loadout.stratagem3
    ? isValid('stratagem', preferences.stratagem || [], challenge.loadout.stratagem3)
    : false;

  const validStratagem4 = challenge.loadout.stratagem4
    ? isValid('stratagem', preferences.stratagem || [], challenge.loadout.stratagem4)
    : false;

  return validDifficulty && validPrimary && validSecondary && validGrenade &&
         validStratagem1 && validStratagem2 && validStratagem3 && validStratagem4;
};
