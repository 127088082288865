const conf = {
  "enemy": [
    "Terminid",
    "Automaton"
  ],
  "difficulty": [
    "4 - Challenging or greater",
    "5 - Hard or greater",
    "6 - Extreme or greater",
    "7 - Suicide Mission or greater",
    "8 - Impossible or greater",
    "9 - Helldive"
  ],
  "duration": [
    "12 minute or more",
    "15 minute or more",
    "40 minute or more"
  ],
  "teamplay": [
    "Solo",
    "Squad"
  ],
  "stars": [
    "1 stars or more",
    "2 stars or more",
    "3 stars or more",
    "4 stars or more",
    "5 stars"
  ],
  "extract": [
    "Yes",
    "No"
  ],
  "primary": [
    "AR-23 Liberator",
    "AR-23P Liberator Penetrator",
    "AR-23C Liberator Concussive",
    "AR-61 Tenderizer",
    "BR-14 Adjudicator",
    "R-63 Diligence",
    "R-63CS Diligence Counter Sniper",
    "MP-98 Knight",
    "SMG-37 Defender",
    "SMG-72 Pummeler",
    "SG-8 Punisher",
    "SG-8S Slugger",
    "SG-225 Breaker",
    "SG-225SP Breaker SPRAY&PRAY",
    "SG-225IE Breaker Incendiary",
    "CB-9 Exploding Crossbow",
    "JAR-5 Dominator",
    "R-36 Eruptor",
    "SG-8P Punisher Plasma",
    "ARC-12 Blitzer",
    "LAS-5 Scythe",
    "LAS-16 Sickle",
    "Plas-1 Scorcher",
    "Plas-101 Purifier"
  ],
  "secondary": [
    "P-2 Peacemaker",
    "P-19 Redeemer",
    "GP-31 Grenade Pistol",
    "LAS-7 Dagger",
    "P-113 Verdict",
    "P-4 Senator"
  ],
  "grenade": [
    "G-6 Frag",
    "G-12 High Explosive",
    "G-10 Incendiary",
    "G-16 Impact",
    "G-13 Incendiary Impact",
    "G-23 Stun",
    "G-3 Smoke",
    "G-123 Thermite"
  ],
  "stratagem": [
    "MG-43 Machine Gun",
    "APW-1 Anti-Material Rifle",
    "M-105 Stalwart",
    "EAT-17 Expendable Anti-Tank",
    "GR-8 Recoilless Rifle",
    "FLAM-40 Flamethrower",
    "AC-8 Autocannon",
    "MG-206 Heavy Machine Gun",
    "RL-77 Airburst Rocket Launcher",
    "RS-422 Railgun",
    "FAF-14 Spear",
    "Orbital Gatling Barage",
    "Orbital Airburst Strike",
    "Orbital 120MM HE Barrage",
    "Orbital 380MM HE Barrage",
    "Orbital Walking Barrage",
    "Orbital Laser",
    "Orbital Railcannon Strike",
    "Eagle Strafing Run",
    "Eagle Airstrike",
    "Eagle Cluster Bomb",
    "Eagle Napalm Airstrike",
    "LIFT-850 Jump Pack",
    "Eagle Smoke Strike",
    "Eagle 110m Rocket Pods",
    "Eagle 500kg Bomb",
    "Orbital Precision Strike",
    "Orbital Gas Strike",
    "Orbital EMS Strike",
    "Orbital Smoke Strike",
    "E/MG-101 HMG Emplacement",
    "FX-12 Shield Generator Relay",
    "A/ARC-3 Tesla Tower",
    "MD-6 Anti-Personnel Minefield",
    "B-1 Supply Pack",
    "GL-21 Grenade Launcher",
    "LAS-98 Laser Cannon",
    "MD-I4 Incendiary Mines",
    "AX/LAS-5 Guard Dog Rover",
    "SH-20 Balistic Shield Backpack",
    "ARC-3 Arc Thrower",
    "LAS-99 Quasar Cannon",
    "SH-32 Shield Generator Pack",
    "A/MG-43 Machine Gun Sentry",
    "A/G-16 Gatling Sentry",
    "A/M-12 Mortar Sentry",
    "AX/AR-23 Guard Dog",
    "A/AC-8 Autocannon Sentry",
    "A/MLS-4X Rocket Sentry",
    "A/M-23 EMS Mortar Sentry",
    "EXO-45 Patriot Exosuit"
  ],
  "BackpackStratagems": [
    "GR-8 Recoilless Rifle",
    "AC-8 Autocannon",
    "RL-77 Airburst Rocket Launcher",
    "FAF-14 Spear",
    "LIFT-850 Jump Pack",
    "FX-12 Shield Generator Relay",
    "B-1 Supply Pack",
    "AX/LAS-5 Guard Dog Rover",
    "SH-20 Balistic Shield Backpack",
    "SH-32 Shield Generator Pack",
    "AX/AR-23 Guard Dog"
  ],
  "SupportWeaponStratagems": [
    "EAT-17 Expendable Anti-Tank",
    "GR-8 Recoilless Rifle",
    "FAF-14 Spear",
    "LAS-99 Quasar Cannon",
    "RL-77 Airburst Rocket Launcher",
    "MG-43 Machine Gun",
    "APW-1 Anti-Material Rifle",
    "M-105 Stalwart",
    "FLAM-40 Flamethrower",
    "AC-8 Autocannon",
    "MG-206 Heavy Machine Gun",
    "RS-422 Railgun",
    "GL-21 Grenade Launcher",
    "LAS-98 Laser Cannon",
    "ARC-3 Arc Thrower"
  ]
};

export default conf;
