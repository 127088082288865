import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MenuIcon, MenuItems } from './index';
import { addresses } from '@my-app/contracts';

const HamburgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = (event) => {
    event.stopPropagation(); // Stop event propagation
    setIsOpen(!isOpen);
  };

  const handleLinkClick = () => {
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const uniswapBchdUrl = `https://app.uniswap.org/swap?theme=dark&inputCurrency=ETH&outputCurrency=${addresses.beachheadToken}&chain=base`;
  const uniswapUsdcUrl = `https://app.uniswap.org/swap?theme=dark&inputCurrency=ETH&outputCurrency=${addresses.usdc}&chain=base`;

  return (
    <>
      <MenuIcon onClick={toggleMenu} onMouseDown={(e) => e.stopPropagation()}>
        <span style={{ transform: isOpen ? 'rotate(45deg) translate(5px, 5px)' : 'rotate(0)' }} />
        <span style={{ opacity: isOpen ? '0' : '1' }} />
        <span style={{ transform: isOpen ? 'rotate(-45deg) translate(7px, -7px)' : 'rotate(0)' }} />
      </MenuIcon>
      {isOpen && (
        <MenuItems ref={menuRef}>
          <Link to="/" onClick={handleLinkClick}>Home</Link>
          <Link to="/helldivers2/browse" onClick={handleLinkClick}>All Chaos Orders</Link>
          <Link to="/helldivers2/list?set=active" onClick={handleLinkClick}>Active Orders</Link>
          <Link to="/helldivers2/list?set=mine" onClick={handleLinkClick}>My Orders</Link>
          <Link to="/helldivers2/judge" onClick={handleLinkClick}>Judge</Link>
          <Link to={uniswapUsdcUrl} target="_blank" onClick={handleLinkClick}>Buy/Sell USDC</Link>
          <Link to={uniswapBchdUrl} target="_blank" onClick={handleLinkClick}>Buy/Sell BCHD</Link>
          <Link to="/about" onClick={handleLinkClick}>About</Link>
          <Link to="/helldivers2/preferences" onClick={handleLinkClick}>Preferences</Link>
          <Link to="https://discord.gg/P7hD7g2rM9" target="_blank" onClick={handleLinkClick}>Community</Link>
          <Link to="https://www.youtube.com/@TheBeachhead" target="_blank" onClick={handleLinkClick}>YouTube</Link>
        </MenuItems>
      )}
    </>
  );
};

export default HamburgerMenu;
